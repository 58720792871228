import React, {useState, useEffect} from "react";
import OrdersDispList from "./OrdersDispList";
import {db} from '../firebase'
import {query, collection, onSnapshot, orderBy} from 'firebase/firestore'
import logo from './Charminar_BlackRing_transparent.png'

const style = {
  bg:`h-screen w-screen p-4 bg-gradient-to-r from-red-500 to-orange-400`,
  container: `bg-slate-100 max-W-[500px] w-full m-auto rounded-md shadow-xl p-4`,
  heading: `text-3xl font-bold text-center text-gray-800 p-2 ml-5 md:ml-8 lg:ml-10`,
  form: `flex justify-between`,
  input: `border p-2 w-full text-xl`,
  button: `border p-4 ml-2 bg-purple-500 rounded-lg text-slate-100 shadow-xl`,
  count: `text-center p-2`,
  img: `w-10 sm:w-20 md:w-30 lg:w-30 h-10 sm:h-20 md:h-30 lg:h-30`
}

function Display() {
  const [orders, setOrders] = useState ([])

  // Read order list from firebase
  useEffect(() => {
    const q = query(collection(db, 'orderlist'), orderBy('text'))
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let ordersArr = []
      querySnapshot.forEach((doc) => {
        ordersArr.push({...doc.data(), id: doc.id})
      });
      setOrders(ordersArr)
    })
    return () => unsubscribe()
  }, [])

  return (
    <div className={style.bg}>
      <div className={style.container}>
      <div className="flex justify-center items-center">
        <img className={style.img} src={logo} alt="Xpress" />
        <h3 className={style.heading}>Orders Display</h3>
      </div>
      <ul>
        {orders.map((order, index)=> (
            <OrdersDispList 
                key={index} 
                order={order} 
                orderLength={orders.length}
            />
        ))}
      </ul>
      {orders.length < 1 ? null : <p className={style.count}>{`We have ${orders.length} orders ready`}</p>}
      </div>
    </div>
  );
}

export default Display;
