// Used in Home component

import React from 'react'
import {FaRegTrashAlt} from 'react-icons/fa'

const style = {
    li: `flex justify-between bg-slate-200 p-4 my-2 capitalize`,
    liComplete: `flex justify-between bg-slate-500 p-4 my-2 capatilize `,
    row: `flex`,
 //   text: `ml-2 cursor-pointer`,
    text: 'ml-3 text-2xl font-bold text-slate-700',
    textComplete: `ml-2 cursor-pointer line-through`,
    button: 'cursor-pointer flex items-center'
}

function OrdersDisp({ order, orderComplete, orderDelete }) {
  return (
    <li className={order.completed ? style.liComplete : style.li}>
        <div className={style.row}>
            <input onChange={() => orderComplete(order)} type="checkbox" checked={order.completed ? 'checked' : ''} />
            <p onClick={() => orderComplete(order)} className={order.completed ? style.textComplete : style.text}>{order.text}</p>
        </div>
        <button onClick={() => orderDelete(order.id)}>{<FaRegTrashAlt />}</button>
    </li>
  )
}

export default OrdersDisp