// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAD-6D_AKhwr1USpHhTlbwPXdYr4ndGwao",
  authDomain: "chxpress-8c596.firebaseapp.com",
  projectId: "chxpress-8c596",
  storageBucket: "chxpress-8c596.appspot.com",
  messagingSenderId: "27621255582",
  appId: "1:27621255582:web:825e2028b8795497680e8f",
  measurementId: "G-ZHVSLWFYCM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app)
