import React, {useState, useEffect} from "react";
import {AiOutlinePlus} from 'react-icons/ai';
import OrdersDisp from "./OrdersDisp";
import {db} from '../firebase'
import {query, collection, orderBy, onSnapshot, updateDoc, doc, addDoc, deleteDoc} from 'firebase/firestore'

const style = {
  bg:`h-screen w-screen p-4 bg-gradient-to-r from-red-500 to-orange-400`,
  container: `bg-slate-100 max-W-[500px] w-full m-auto rounded-md shadow-xl p-4`,
  heading: `text-3xl font-bold text-center text-gray-800 p-2`,
  form: `flex justify-between`,
  input: `border p-2 w-full text-xl`,
  button: `border p-4 ml-2 bg-purple-500 rounded-lg text-slate-100 shadow-xl`,
  count: `text-center p-2`
}

function Home() {
  const [orders, setOrders] = useState ([])
  const [input, setInput] = useState('')

  // Create order list
  const createOrder = async (e) => {
    e.preventDefault(e)
    if(input === '') {
      //alert('Please enter a valid order')
      return
    }
    await addDoc(collection(db, 'orderlist'), {
      text: input,
      completed: false
    })
    setInput('')
  }

  // Read order list from firebase
  useEffect(() => {
    const q = query(collection(db, 'orderlist'), orderBy('text'))
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let ordersArr = []
      querySnapshot.forEach((doc) => {
        ordersArr.push({...doc.data(), id: doc.id})
      });
      setOrders(ordersArr)
    })
    return () => unsubscribe()
  }, [])

  // Update order in firebase
  const orderComplete = async (order) => {
    await updateDoc(doc(db, 'orderlist', order.id), {
      completed: !order.completed
    } )
  }

  // Delete order
  const orderDelete = async(id) =>  {
    await deleteDoc(doc(db, 'orderlist', id))
  }


  return (
    <div className={style.bg}>
      <div className={style.container}>
      <h3 className={style.heading}>Orders Display</h3>
      <form onSubmit={createOrder} className={style.form}>
        <input value={input} onChange={(e) => setInput(e.target.value)} className={style.input} placeholder="Add Order no or Customer name"/>
        <button className={style.button}> <AiOutlinePlus size={30}/> </button>
      </form>
      <ul>
        {orders.map((order, index)=> (
            <OrdersDisp 
                key={index} 
                order={order} 
                orderComplete={orderComplete} 
                orderDelete={orderDelete} 
            />
        ))}
      </ul>
      {orders.length < 1 ? null : <p className={style.count}>{`We have ${orders.length} orders ready`}</p>}
      </div>
    </div>
  );
}

export default Home;
