import {Routes, Route } from 'react-router-dom'

import Home from './components/Home';
import Display from './components/Display';
import ErrorPage from './components/ErrorPage'

function App() {

  return (
    <> 
      <Routes>
        <Route path='/home' element={<Home />} />
        <Route path='/display' element={<Display />} />
        <Route path='*' element={<ErrorPage />} />
      </Routes>
    </>
  );
}

export default App;
