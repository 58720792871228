// Used in Display component

import React from 'react'

const style = {
    li: `flex justify-center bg-slate-200 p-4 my-2 capitalize`,
    row: `flex `,
    text: 'font-bold text-slate-700',
}

function OrdersDispList({ order, orderLength }) {
  return (
    <li className={style.li}>
        <div className={style.row}>
            <p className={`${style.text} ${orderLength > 5 ? 'text-xl' : 'text-2xl'}`}>{order.text}</p>
        </div>
    </li>
  )
}

export default OrdersDispList